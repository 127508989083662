import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ["button", "input"]
  static values = { copied: String }

  copy() {
    const text = this.inputTarget.innerText || this.inputTarget.value
    navigator.clipboard.writeText(text)
    const buttonText = this.buttonTarget.innerText
    this.buttonTarget.innerText = this.copiedValue || "Copied!"

    let that = this
    setTimeout(function () {
      that.buttonTarget.innerText = buttonText
    }, 2000)
  }
}
