import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import "tom-select/dist/css/tom-select.default.css"

// Connects to data-controller="simple-select"
export default class extends Controller {
  static targets = ["select"]

  connect() {
    new TomSelect(this.selectTarget, {
      create: false
    })
  }
}
